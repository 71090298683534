export interface ClientDetails {
    "mettlClientId": number,
    "mpaasClientId": number,
    "emailId": string,
    "name": string,
    "accountStatus": string,
    "accountType": string,
    "accountManager": string,
    "lastLoginDate": string,
    "creationDate": string,
    "organization": string,
    "zohoId": number,
    "zohoAccountName": string,
    "blockedStatus": boolean,
    "encryptedEmailId": string,
    "encryptedOrgnization": string,
    "newMpaasEnabledFlagStatus": boolean
}

export interface MpsKeysDataModels {
    "publicKey": string,
    "privateKey": string,
    "emailId": string,
    "enabled": Boolean,
    "keysGenerated": boolean,
    "mpaasClientId": number
}

export interface LtiKeysDataModels {
    "publicKey": string,
    "privateKey": string,
    "emailId": string,
    "enabled": Boolean,
    "keysGenerated": boolean
}

export interface AccountDetails {
    "clientDetails": ClientDetails,
    "mpsKeys": MpsKeysDataModels,
    "ltiKeys": LtiKeysDataModels
}

export interface ProctoringSettings {
    "recording": boolean,
    "authorization": boolean,
    "screenCapture": boolean,
    "audioOptional": boolean,
    "navigationControl": boolean,
    "msb": boolean,
    "audioProctoring": boolean,
    "audioProctoringToleranceLimit": number
}

export interface AccountUsageSummary {
    "mpaasClientId": number,
    "clientEmail": string,
    "proctoringDuration": number,
    "videoRecordingDuration": number,
    "groupsCount": number,
    "usersCount": number,
    "avgProctoringDuration": number
}

export interface ZohoAccountDetails {
    "mettlClientId": number,
    "emailId": string,
    "lastLoginDate": string,
    "zohoId": string,
    "zohoAccountName": string
}

export interface SubUsers {
    "firstName": string,
    "lastName": string,
    "emailID": string,
    "userID": number,
    "status": string,
    "creationDate": Date,
    "lastLoginDate": Date
}

export interface UserMetaData {
    "proctoringInstanceId": number,
    "groupName": string,
    "emailId": string,
    "screenCapture": boolean,
    "authorization": boolean,
    "fullScreenRecording": boolean,
    "proctoringDuration": number,
    "date": string,
    "reportUrl": string,
}

export class DetailedAccountUsageSummaryModels {
    "totalCount"?: number;
    "userMetaData"?: UserMetaData[];
}

export class HttpErrorDataModel {
    "message": string;
    "error": any;
}