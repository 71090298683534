import { mpaasHttp, httpDataStatus } from "./MpaasHttpClient";
import UrlConstants from "./UrlConstants";
import { mpaasHttpAction } from "./MpaasHttpActionHandler";

const MpaasAdminPanelApi = {
    fetchClientDetails: (data: Object) => {
        return new Promise((resolve, reject) => {
            mpaasHttp.get(UrlConstants.CLIENT_DETAILS, data).then((resp: any) => {
                if (resp.status === httpDataStatus.SUCCESS) {
                    resolve(resp.clientDetails);
                } else {
                    let errorDetails = mpaasHttpAction.makeErrorData("Error while fetching client details", resp);
                    reject(errorDetails);
                }
            }, (error) => {
                let errorDetails = mpaasHttpAction.makeErrorData("Error while fetching client details", error);
                reject(errorDetails);
            });
        });
    },
    fetchMpsKeysData: (data: Object) => {
        return new Promise((resolve, reject) => {
            mpaasHttp.get(UrlConstants.MPS_KEYS, data).then((resp: any) => {
                let status, mpsKeysData: any;
                ({ status, ...mpsKeysData } = resp);

                if (status === httpDataStatus.SUCCESS) {
                    resolve(mpsKeysData);
                } else {
                    let errorDetails = mpaasHttpAction.makeErrorData("Error while fetching mps keys data", resp);
                    reject(errorDetails);
                }
            }, (error) => {
                let errorDetails = mpaasHttpAction.makeErrorData("Error while fetching mps keys data", error);
                reject(errorDetails);
            });
        });
    },
    fetchLtiKeysData: (data: Object) => {
        return new Promise((resolve, reject) => {
            mpaasHttp.get(UrlConstants.LTI_KEYS, data).then((resp: any) => {
                let status, ltiKeysData: any;
                ({ status, ...ltiKeysData } = resp);

                if (status === httpDataStatus.SUCCESS) {
                    resolve(ltiKeysData);
                } else {
                    let errorDetails = mpaasHttpAction.makeErrorData("Error while fetching lti keys data", resp);
                    reject(errorDetails);
                }
            }, (error) => {
                let errorDetails = mpaasHttpAction.makeErrorData("Error while fetching lti keys data", error);
                reject(errorDetails);
            });
        });
    },
    updateNewMpaasFlag: (data: object) => {
        return new Promise((resolve, reject) => {
            mpaasHttp.put(UrlConstants.UPDATE_MPAAS_FLAG, data).then((resp: any) => {
                if (resp.status === httpDataStatus.SUCCESS) {
                    resolve(resp);
                } else {
                    let errorDetails = mpaasHttpAction.makeErrorData("Error while updating new mpaas flag", resp);
                    reject(errorDetails);
                }
            }, (error) => {
                let errorDetails = mpaasHttpAction.makeErrorData("Error 2. while updating new mpaas flag", error);
                reject(errorDetails);
            });
        });
    },
    fetchProctoringSettings: (data: Object) => {
        return new Promise((resolve, reject) => {
            mpaasHttp.get(UrlConstants.GET_PROCTORING_SETTINGS, data).then((resp: any) => {
                if (resp.status === httpDataStatus.SUCCESS && resp.proctoringSettings) {
                    resolve(resp.proctoringSettings);
                } else {
                    let errorDetails = mpaasHttpAction.makeErrorData("Error while fetching proctoring settings", resp);
                    reject(errorDetails);
                }
            }, (error) => {
                let errorDetails = mpaasHttpAction.makeErrorData("Error while fetching proctoring settings", error);
                reject(errorDetails);
            });
        });
    },
    fetchAccountUsageSummary: (data: object) => {
        return new Promise((resolve, reject) => {
            mpaasHttp.post(UrlConstants.ACCOUNT_USAGE_SUMMARY, data).then((resp: any) => {
                if (resp.status === httpDataStatus.SUCCESS && resp.usageSummaryList && resp.usageSummaryList.length > 0) {
                    resolve(resp.usageSummaryList);
                } else {
                    let errorDetails = mpaasHttpAction.makeErrorData("Error while fetching account usage summary", resp);
                    reject(errorDetails);
                }
            }, (error) => {
                let errorDetails = mpaasHttpAction.makeErrorData("Error while fetching account usage summary", error);
                reject(errorDetails);
            });
        });
    },
    fetchZohoAccountDetails: (data: object) => {
        return new Promise((resolve, reject) => {
            mpaasHttp.post(UrlConstants.ZOHO_ACCOUNT_DETAILS, data).then((resp: any) => {
                if (resp.status === httpDataStatus.SUCCESS && resp.zohoAccountDetails && resp.zohoAccountDetails.length > 0) {
                    resolve(resp.zohoAccountDetails);
                } else {
                    let errorDetails = mpaasHttpAction.makeErrorData("Error while fetching zoho account details", resp);
                    reject(errorDetails);
                }
            }, (error) => {
                let errorDetails = mpaasHttpAction.makeErrorData("Error while fetching zoho account details", error);
                reject(errorDetails);
            });
        });
    },
    fetchSubUser: (data: Object) => {
        return new Promise((resolve, reject) => {
            mpaasHttp.get(UrlConstants.SUB_USER, data).then((resp: any) => {
                if (resp.status === httpDataStatus.SUCCESS && resp.subUserDetailsList && resp.subUserDetailsList.length > 0) {
                    resolve(resp.subUserDetailsList);
                } else {
                    let errorDetails = mpaasHttpAction.makeErrorData("Error while fetching sub users data.", resp);
                    reject(errorDetails);
                }
            }, (error) => {
                let errorDetails = mpaasHttpAction.makeErrorData("Error while fetching sub users data.", error);
                reject(errorDetails);
            });
        });
    },
    fetchDetailedUsageSummary: (data: object) => {
        return new Promise((resolve, reject) => {
            mpaasHttp.get(UrlConstants.DETAILED_ACCOUNT_USAGE_SUMMARY, data).then((resp: any) => {
                if (resp.status === httpDataStatus.SUCCESS && resp.data) {
                    resolve(resp.data);
                } else {
                    let errorDetails = mpaasHttpAction.makeErrorData("Error while fetching detailed account usage summary.", resp);
                    reject(errorDetails);
                }
            }, (error) => {
                let errorDetails = mpaasHttpAction.makeErrorData("Error while fetching detailed account usage summary.", error);
                reject(errorDetails);
            });
        });
    },
    updateProctoringSetting: (data: object) => {
        return new Promise((resolve, reject) => {
            mpaasHttp.put(UrlConstants.UPDATE_PROCTORING_SETTINGS, data).then((resp: any) => {
                if (resp.status === httpDataStatus.SUCCESS) {
                    resolve(resp);
                } else {
                    let errorDetails = mpaasHttpAction.makeErrorData("Error while saving proctoring settings. Error:", resp);
                    reject(errorDetails);
                }
            }, (error) => {
                let errorDetails = mpaasHttpAction.makeErrorData("Error while saving proctoring settings. Error:", error);
                reject(errorDetails);
            });
        });
    },
    updateClientStatus: (data: object) => {
        return new Promise((resolve, reject) => {
            mpaasHttp.put(UrlConstants.UPDATE_CLIENT_STATUS, data).then((resp: any) => {
                if (resp.status === httpDataStatus.SUCCESS) {
                    resolve(resp);
                } else {
                    let errorDetails = mpaasHttpAction.makeErrorData("Error while updating client status. Error:", resp);
                    reject(errorDetails);
                }
            }, (error) => {
                let errorDetails = mpaasHttpAction.makeErrorData("Error while updating client status. Error:", error);
                reject(errorDetails);
            });
        });
    },
    downloadAccountUsageSummary: (data: object) => {
        return new Promise((resolve, reject) => {
            mpaasHttp.post(UrlConstants.DOWNLOAD_ACCOUNT_USAGE_SUMMARY, data).then((resp: any) => {
                if (resp.status === httpDataStatus.SUCCESS) {
                    resolve(resp);
                } else {
                    let errorDetails = mpaasHttpAction.makeErrorData("Error while downloading account usage summary.", resp);
                    reject(errorDetails);
                }
            }, (error) => {
                let errorDetails = mpaasHttpAction.makeErrorData("Error while downloading account usage summary.", error);
                reject(errorDetails);
            });
        });
    },
    downloadDetailedAccountUsageSummary: (data: object) => {
        return new Promise((resolve, reject) => {
            mpaasHttp.get(UrlConstants.DOWNLOAD_DETAILED_ACCOUNT_USAGE_SUMMARY, data).then((resp: any) => {
                if (resp.status === httpDataStatus.SUCCESS) {
                    resolve(resp);
                } else {
                    let errorDetails = mpaasHttpAction.makeErrorData("Error while downloading detailed account usage summary.", resp);
                    reject(errorDetails);
                }
            }, (error) => {
                let errorDetails = mpaasHttpAction.makeErrorData("Error while downloading detailed account usage summary.", error);
                reject(errorDetails);
            });
        });
    },
    fetchAllAccountUsageSummary: (data: object) => {
        return new Promise((resolve, reject) => {
            mpaasHttp.get(UrlConstants.ALL_ACCOUNT_USAGE_SUMMARY, data).then((resp: any) => {
                if (resp.status === httpDataStatus.SUCCESS && resp.data) {
                    resolve(resp.data);
                } else {
                    let errorDetails = mpaasHttpAction.makeErrorData("Error while fetching all account usage summary", resp);
                    reject(errorDetails);
                }
            }, (error) => {
                let errorDetails = mpaasHttpAction.makeErrorData("Error while fetching all account usage summary", error);
                reject(errorDetails);
            });
        });
    },
    updateApiEnableDisableStatus: (data: object) => {
        return new Promise((resolve, reject) => {
            mpaasHttp.put(UrlConstants.MPS_KEYS, data).then((resp: any) => {
                if (resp.status === httpDataStatus.SUCCESS) {
                    resolve(resp);
                } else {
                    let errorDetails = mpaasHttpAction.makeErrorData("Error while updating api enable disable status.", resp);
                    reject(errorDetails);
                }
            }, (error) => {
                let errorDetails = mpaasHttpAction.makeErrorData("Error while updating api enable disable status.", error);
                reject(errorDetails);
            });
        });
    },
    updateLtiEnableDisableStatus: (data: object) => {
        return new Promise((resolve, reject) => {
            mpaasHttp.put(UrlConstants.LTI_KEYS, data).then((resp: any) => {
                if (resp.status === httpDataStatus.SUCCESS) {
                    resolve(resp);
                } else {
                    let errorDetails = mpaasHttpAction.makeErrorData("Error while updating lti enable disable status.", resp);
                    reject(errorDetails);
                }
            }, (error) => {
                let errorDetails = mpaasHttpAction.makeErrorData("Error while updating lti enable disable status.", error);
                reject(errorDetails);
            });
        });
    },
    reGenerateApiKeys: (data: object) => {
        return new Promise((resolve, reject) => {
            mpaasHttp.put(UrlConstants.RE_GENERATE_API_KEYS, data).then((resp: any) => {
                if (resp.status === httpDataStatus.SUCCESS) {
                    resolve(resp);
                } else {
                    let errorDetails = mpaasHttpAction.makeErrorData("Error while re-generating api keys.", resp);
                    reject(errorDetails);
                }
            }, (error) => {
                let errorDetails = mpaasHttpAction.makeErrorData("Error while re-generating api keys.", error);
                reject(errorDetails);
            });
        });
    },
    reGenerateLtiKeys: (data: object) => {
        return new Promise((resolve, reject) => {
            mpaasHttp.put(UrlConstants.RE_GENERATE_LTI_KEYS, data).then((resp: any) => {
                if (resp.status === httpDataStatus.SUCCESS) {
                    resolve(resp);
                } else {
                    let errorDetails = mpaasHttpAction.makeErrorData("Error while re-generating lti keys.", resp);
                    reject(errorDetails);
                }
            }, (error) => {
                let errorDetails = mpaasHttpAction.makeErrorData("Error while re-generating lti keys.", error);
                reject(errorDetails);
            });
        });
    },
    generateApiKeys: (data: object) => {
        return new Promise((resolve, reject) => {
            mpaasHttp.post(UrlConstants.MPS_KEYS, data).then((resp: any) => {
                if (resp.status === httpDataStatus.SUCCESS) {
                    resolve(resp);
                } else {
                    let errorDetails = mpaasHttpAction.makeErrorData("Error while generating api keys.", resp);
                    reject(errorDetails);
                }
            }, (error) => {
                let errorDetails = mpaasHttpAction.makeErrorData("Error while generating api keys.", error);
                reject(errorDetails);
            });
        });
    },
    generateLtiKeys: (data: object) => {
        return new Promise((resolve, reject) => {
            mpaasHttp.post(UrlConstants.LTI_KEYS, data).then((resp: any) => {
                if (resp.status === httpDataStatus.SUCCESS) {
                    resolve(resp);
                } else {
                    let errorDetails = mpaasHttpAction.makeErrorData("Error while generating lti keys.", resp);
                    reject(errorDetails);
                }
            }, (error) => {
                let errorDetails = mpaasHttpAction.makeErrorData("Error while generating lti keys.", error);
                reject(errorDetails);
            });
        });
    },
    getLoginKey: () => {
        return new Promise((resolve, reject) => {
            mpaasHttp.get(UrlConstants.LOGIN_KEY).then((resp: any) => {
                if(resp) {
                    resolve(resp);
                } else {
                    let errorDetails = mpaasHttpAction.makeErrorData("Error while getting login key for support login.", resp);
                    reject(errorDetails);
                }
            }, (error) => {
                let errorDetails = mpaasHttpAction.makeErrorData("Error while getting login key for support login.", error);
                reject(errorDetails);
            })
        })
    },
    getAudioDatawithPid: (data: any) => {
        return new Promise((resolve, reject) => {
            mpaasHttp.get(UrlConstants.AUDIOINFO+'?pid='+data.pid+'&page=NEXT&pageSize=5&pageNo=1').then((resp: any) => {
                resolve(resp);
            }, (error) => {
                reject(error);
            });
        });
    },
    getAudioDataWithGid: (data: any) => {
        return new Promise((resolve, reject) => {
            mpaasHttp.get(UrlConstants.AUDIOINFO+'?firstPid='+data.fpid+'&groupId='+data.gid+'&page='+data.page+'&pageSize=7&pageNo='+data.pNo+'').then((resp: any) => {
                resolve(resp);
            }, (error) => {
                reject(error);
            });
        });
    },
    getAudioDatawithDate: (data: any) => {
        return new Promise((resolve, reject) => {
            mpaasHttp.get(UrlConstants.AUDIOINFO+'?firstPid='+data.fpid+'&startDate='+data.startDate+'&endDate='+data.endDate+'&page='+data.page+'&pageSize=7&pageNo='+data.pNo+'').then((resp: any) => {
                resolve(resp);
            }, (error) => {
                 reject(error);
            });
        });
    },
    getGroupProctoringStatistics: (data: any) => {
        return new Promise((resolve, reject) => {
            mpaasHttp.post(UrlConstants.GROUP_PROCTORING_STATISTICS, data).then((resp: any) => {
                if (resp.status === httpDataStatus.SUCCESS) {
                    resolve(resp);
                } else {
                    let errorDetails = mpaasHttpAction.makeErrorData(resp.error.message, resp);
                    reject(errorDetails);
                }
            }, (error) => {
                let errorDetails = mpaasHttpAction.makeErrorData(error.message, error);
                reject(errorDetails);
            });
        });
    },
    getCandidateProctoringStatistics: (data: any) => {
        return new Promise((resolve, reject) => {
            mpaasHttp.post(UrlConstants.CANDIDATE_PROCTORING_STATISTICS, data).then((resp: any) => {
                if (resp.status === httpDataStatus.SUCCESS) {
                    resolve(resp);
                } else {
                    let errorDetails = mpaasHttpAction.makeErrorData(resp.error.message, resp);
                    reject(errorDetails);
                }
            }, (error) => {
                let errorDetails = mpaasHttpAction.makeErrorData(error.message, error);
                reject(errorDetails);
            });
        });
    },
    downloadGroupProctoringStatistics: (data: any) => {
        mpaasHttp.download(UrlConstants.DOWNLOAD_GROUP_PROCTORING_STATISTICS+'?clientId='+data.clientId+'&startDate='+data.startDate+'&endDate='+data.endDate+'&groupIds='+data.groupIds);
    },
    downloadCandidateProctoringStatistics: (data: any) => {
        mpaasHttp.download(UrlConstants.DOWNLOAD_CANDIDATE_PROCTORING_STATISTICS+'?clientId='+data.clientId+'&startDate='+data.startDate+'&endDate='+data.endDate+'&groupId='+data.groupId);
    }
}

export default MpaasAdminPanelApi;