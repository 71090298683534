import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import LoginComponent from "./components/login/LoginComponent";
import ProtectedRoute from "./utils/ProtectedRoute";
import HomeComponent from "./components/home/HomeComponent";
import PageNotFoundComponent from "./components/errors/PageNotFoundComponent";
import AccountDetailsComponent from "./components/accountDetails/AccountDetailsComponent";
import MonitorClientsActivityComponent from "./components/monitorClientsActivity/MonitorClientsActivityComponent";
import MpaasLtiKeyHandlingComponent from "./components/mpaasLtiKeyHandling/MpaasLtiKeyHandlingComponent";
import ViewCandidateDetailsComponent from "./components/viewCandidateDetails/ViewCandidateDetailsComponent";
import ViewRunningTestsComponent from "./components/viewRunningTests/ViewRunningTestsComponent";
import AudioInfoComponent from "./components/audioProctoringInfo/AudioInfoComponent";
import ViewCandidateProctoringStatistics from "./components/proctoringStatistics/ViewCandidateProctoringStatistics";
import GlobalSettings from "./components/globalSettings/GlobalSettings";
import ProhibitedSoftwares from './components/globalSettings/msbSettings/ProhibitedSoftwares';


class AppRoutingComponent extends Component {
    render() {
        return (
            <Switch>
                <Route exact path={["/", "/login"]} component={LoginComponent} />
                <ProtectedRoute exact path="/home" component={HomeComponent} />
                <ProtectedRoute exact path="/accountDetails" component={AccountDetailsComponent} />
                <ProtectedRoute exact path="/monitorClientsActivity" component={MonitorClientsActivityComponent} />
                <ProtectedRoute exact path="/mpaasLtiKeyHandling" component={MpaasLtiKeyHandlingComponent} />
                <ProtectedRoute exact path="/viewCandidateDetails" component={ViewCandidateDetailsComponent} />
                <ProtectedRoute exact path="/viewRunningTests" component={ViewRunningTestsComponent} />
                <ProtectedRoute exact path="/audioProctoringInfo" component={AudioInfoComponent} />
                <ProtectedRoute exact path="/viewCandidateProctoringStatistics" component={ViewCandidateProctoringStatistics} />
                <ProtectedRoute exact path="/settings" component={GlobalSettings} />
                <ProtectedRoute exact path="/settings/msbSettings/prohibitedSoftwares" component={ProhibitedSoftwares} />
                <Route path="*" component={PageNotFoundComponent} />
            </Switch>
        )
    }
}

export default AppRoutingComponent;
