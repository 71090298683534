import React from 'react';
import { AiOutlineHome, AiOutlineStop } from "react-icons/ai";
import { VscSettings } from "react-icons/vsc";
import { Link } from "react-router-dom";
import './globalSettings.css';


const GlobalSettings: React.FC = () => {
    return (
        <div>
            <div className='breadcrumb-section'>
                <AiOutlineHome /> <span className="small"> &gt; <span className="text-muted">Settings</span> </span>
            </div>

            <div className='mt-3'>
                <h5>View Settings</h5>
                <p className="text-muted small">You can view / define settings for SecureProctor</p>
            </div>

            <div className='mt-5'>
                <div>
                    <div className='d-flex align-items-center mb-3'>
                        <p className='h5 text-quill-lighter mr-2 mb-0'>MSB Settings</p>
                        <hr className='flex-grow-1' />
                    </div>

                    <div>
                        <Link to="/settings/msbSettings/prohibitedSoftwares" className='text-body text-decoration-none border rounded-lg d-flex flex-column justify-content-center align-items-center shadow-sm settings-tile'>
                            <AiOutlineStop size={48} />
                            <p className='px-2 text-center mt-2 mb-0'>Prohibited Softwares</p>
                        </Link>
                    </div>
                </div>

                <div className='mt-4'>
                    <div className='d-flex align-items-center mb-3'>
                        <p className='h5 text-quill-lighter mr-2 mb-0'>Miscellaneous</p>
                        <hr className='flex-grow-1' />
                    </div>

                    <div>
                        <Link onClick={() => alert("Feature Coming Soon!")} to="/settings" className='text-body text-decoration-none border rounded-lg d-flex flex-column justify-content-center align-items-center shadow-sm settings-tile'>
                            <VscSettings size={48} />
                            <p className='px-2 text-center mt-2 mb-0'>Other Settings</p>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GlobalSettings;